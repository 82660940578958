@import url(https://fonts.googleapis.com/css2?family=Chilanka&display=swap);
/* global */
    body {
        font-family: 'Chilanka' !important;
    }
    a {
        text-decoration: none !important;
    }
    .link-unstyled {
        text-decoration: none !important;
        color: #000000 !important;
    }
    .link-unstyled-white {
        text-decoration: none !important;
        color: #ffffff !important;
    }
    .navbar {
        padding: 0px 0px 5px 0px !important;
    }
/* end:: global */

/* colors */
    .bg-light {
        background-color: #f8f9fa,
    }
/* end:: colors */

/* navigation */
    .navigation-container {
        padding-top: 5px;
    }
    .nav-link {
        margin-left: 15px !important;
        color: white !important;
    }
    .nav-link:first-child {
        margin-right: 0px !important;
    }
/* end:: navigation */

/* sections */
    .default-section {
        padding: 60px 0px 60px 0px;
        text-align: center;
    }
    .text-section {
        padding: 60px 0px 60px 0px;
        text-align: center;
    }
    p:last-of-type {
        margin-bottom: 0px !important;
    }
    .text-section-heading {
        padding-bottom: 10px;
    }
    .single-section {
        padding: 30px 0px 30px 0px;
    }
    .cropped-section {
        padding: 60px 0px 0px 0px;
        text-align: center;
    }
    .products-section {
        padding: 30px 0px 0px 0px;
    }
/* end:: sections */

/* Footer */
    .footer-wrapper {
        padding: 20px 0px 10px 0px;
        background-color: #505050;
        color: #ffffff;
    }
/* end:: Footer */

/* products */
    .product-card {
        text-align: left;
    }
    .product-card-title {
        font-size: 16px;
    }
    .product-card-subtitle {
        font-size: 16px;
        margin-bottom: 15px;
    }
    .product-card-image {
        margin-bottom: 15px;
        max-height: 246px;
    }
    .product-card-image-single {
        margin-bottom: 15px;
        max-height: 250px;
        border: 1px solid #eeeeee;
    }
    .product-card-wrapper {
        text-align: center !;
    }
    .single-product-title {
        font-size: 24px;
    }
    .single-product-subtitle {
        margin-bottom: 10px;
    }
    .single-product-desc-title {
        font-size: 24px;
    }
    .single-product-desc > p {
        margin-bottom: 0px !important;
    }
/* end:: products */

/* buttons */
    .b-btn {
        padding-bottom: 2px !important;
    }
/* end:: buttons */

/* event */
    .event-card {
        text-align: left;
    }
    .event-card-description > p {
        margin-bottom: 0px !important;
    }
    .event-card-title {
        font-size: 22px;
    }
    .event-card-date {
        margin-bottom: 10px;
    }
/* end:: event */

/* contact section */
    .contact-section {
        padding: 60px 0px 50px 0px;
    }
    .google-map {
        height: 350px;
    }
    .contact-content-section {
        text-align: left;
    }
    .contact-item {
        font-size: 22px;
    }
    .google-map-wrapper {
        margin-bottom: 20px;
    }
    .form-section {
        text-align: center;
        padding: 60px 0px 50px 0px;
    }
/* end:: contact section */

/* category carousel */
    .category-carousel {
        margin-top: 30px;
    }
/* end:: category carousel */

.contact-content-section {
    margin-bottom: 20px;
}

.contact-content-section:last-of-type {
    margin-bottom: 0px;
}

.product-col {
    margin-bottom: 30px;
}

.footer-text {
}

@media (min-width: 576px) { 
    .contact-content-section {
        margin-bottom: 30px;
    }
 }

@media (min-width: 768px) { 
    .contact-content-section {
        margin-bottom: 0px;
    }
 }

@media (min-width: 992px) { 
    .contact-content-section {
        margin-bottom: 0px;
    }
 }

@media (min-width: 1200px) { 
    .contact-content-section {
        margin-bottom: 0px;
    }
 }
